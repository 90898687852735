export default {
  xxs: "16.8px",
  xs: "19.6px",
  sm: "22px",
  md: "25.2px",
  lg: "28px",
  xxl: "32px",

  landingXxs: "13px",
  landingXs: "14px",
  landingSm: "24px",
  landingMd: "36px",
  landingLg: "52px",
  landingXxl: "80px",
};
