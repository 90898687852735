export default {
  xs: "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xl: "20px",
  xxl: "32px",
  titleL: "48px",
  landingTitleXl: "72px",
};
