// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
export default {
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
  extraBlack: 950,
};
