export const SORT_DIRECTION = ["asc", "desc"];
export const INITIAL_LIMIT = "10";
export const INITIAL_PAGE = 0;
export const DAYS_UNTIL_NEXT_ACTION = "days-until-next-action";
export const SEARCH_PARAMS = {
  token: "token",
};

export const PER_PAGE_OPTIONS = [
  { value: 10, label: INITIAL_LIMIT },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
];

export const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;

export const LINKEDIN_REGEX =
  /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)/gm;

export const COMBINED_REGEX =
  /^(N\/A|((http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)))/gm;

export const EMPTY_ARRAY = Object.freeze([]);

export const EMPTY_OBJECT = Object.freeze({});

export const SUPPORT_EMAIL = "mica@marvelous.works";

export const TEST_EMAIL = "mr.externaluser.test@gmail.com";
