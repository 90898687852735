import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider } from "react-router-dom";
import { Global } from "@emotion/react";
import queryClient from "queryClient";
import globalCss from "assets/css/global.css";
import LocalStorageProvider from "context/localStorageContext/LocalStorageProvider";
import router from "routing";
import packageJson from "../package.json";

export default function App() {
  return (
    <LocalStorageProvider>
      <Global styles={globalCss} />

      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />

        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />

        <span style={{ display: "none" }} data-version={packageJson.version} />
      </QueryClientProvider>
    </LocalStorageProvider>
  );
}
