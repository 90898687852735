import { css } from "@emotion/react";
import colors from "assets/css/colors";
import fontFamily from "assets/css/fontFamily";
import flexCss from "assets/css/flex.css";
import typographyCss from "assets/css/typography.css";

export default css([
  css`
    html,
    body,
    #root {
      min-height: 100vh;
      font-family: ${fontFamily.roboto};
    }

    #root,
    main {
      display: flex;
      flex-direction: column;
    }

    body {
      margin: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: ${colors.bgGrey};
    }

    main {
      flex: 1;
      padding: 30px 0;
    }

    button,
    a {
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }
    }

    button {
      border: none;
      padding: 0;
      background-color: transparent;
      font-size: unset;
      text-align: start;
    }

    input[type="number"] {
      padding-right: 12px;
    }

    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        z-index: 5;
      }
    }

    .reactour__popover {
      border-radius: 2px !important;
      padding: 15px !important;
      max-width: 248px !important;

      & div:last-of-type {
        border-top: 1px solid ${colors.strokeGrey};
        padding-top: 15px;
        margin: 0;
      }

      & button {
        top: 15px;
        right: 15px;
      }
    }

    .email-quill-wrapper {
      .ql-toolbar.ql-snow + .ql-container.ql-snow {
        min-height: 200px;
        display: flex;
        align-items: stretch;
      }

      .ql-editor {
        height: auto;
        flex-basis: 100%;
      }
    }

    .ql-editor {
      white-space: unset !important;

      ul,
      ol {
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;
        padding-inline-start: 2em !important;
      }
    }

    // to fix react-quill placeholder input
    .ql-placeholders {
      width: 145px;

      .ql-picker-options {
        max-height: 172px;
        overflow: auto;
      }

      .ql-picker-label {
        display: flex !important;
        align-items: center;
      }
    }

    .container {
      max-width: 1310px;
      margin: 0 auto;
      padding: 0 15px;
      width: 100%;
      box-sizing: border-box;
    }

    .Toastify__toast-container {
      // to fix position of notification pop up
      &--top-right {
        top: 4.5em !important;
      }
    }
  `,
  flexCss,
  typographyCss,
]);
