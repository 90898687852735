import LOCAL_STORAGE_KEYS from "constants/localStorageKeys";
import { APPLICATION_JSON, AUTHORIZATION, CONTENT_TYPE } from "constants/headers";
import localStorageHelper from "helpers/storage";

export default function createHeaders(init) {
  const headers = new Headers(init);

  if (!headers.has(CONTENT_TYPE)) {
    headers.set(CONTENT_TYPE, APPLICATION_JSON);
  }

  const token = localStorageHelper.get(LOCAL_STORAGE_KEYS.token);
  if (token && !headers.has(AUTHORIZATION)) {
    headers.set(AUTHORIZATION, token);
  }

  return headers;
}
