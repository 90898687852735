/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import AUTH_STATUS from "constants/auth";
import { SEARCH_PARAMS } from "constants/common";
import LOCAL_STORAGE_KEYS from "constants/localStorageKeys";
import { AUTHORIZATION } from "constants/headers";
import authContext from "context/authContext/context";
import useLocalStorage from "context/localStorageContext/useLocalStorage";
import fetchClient from "api/client";
import useRouterSearchParams from "hooks/useRouterSearchParams";

const ProviderComponent = authContext.Provider;

export default function AuthProvider({ children }) {
  const [, setSearchParams] = useRouterSearchParams();
  const { getItem, setItem, removeItem } = useLocalStorage();
  const [authStatus, setAuthStatus] = useState(AUTH_STATUS.pending);
  const storageToken = getItem(LOCAL_STORAGE_KEYS.token);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get(SEARCH_PARAMS.token) || storageToken;

    fetchClient
      .get("authed", { headers: { [AUTHORIZATION]: token } })
      .then((response) => {
        const authed = response?.authed;
        if (authed) {
          setItem(LOCAL_STORAGE_KEYS.token, token);
        } else {
          removeItem(LOCAL_STORAGE_KEYS.token);
        }

        setAuthStatus(authed ? AUTH_STATUS.authorized : AUTH_STATUS.unauthorized);
      })
      .catch((err) => {
        const error = new Error(err.response?.message || err.message);
        toast.error(error.message);
        setAuthStatus(AUTH_STATUS.unauthorized);
        removeItem(LOCAL_STORAGE_KEYS.token);
      });
  }, [storageToken, setItem, removeItem]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.get(SEARCH_PARAMS.token)) return;

    setSearchParams(
      (prevSearchParams) => {
        prevSearchParams.delete(SEARCH_PARAMS.token);

        return prevSearchParams;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  return <ProviderComponent value={authStatus}>{children}</ProviderComponent>;
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
