import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { prospectKey } from "queryKeys/prospectKey";

const STALE_TIME = 24 * 60 * 60 * 1_000;

function logout() {
  window.location.reload();
}

const HANDLERS = {
  401: logout,
  403: logout,
};

export const HANDLER_STATUSES = Object.keys(HANDLERS);

function onError(error) {
  HANDLERS[error.response?.statusCode]?.(error);
}

const queryCache = new QueryCache({
  onError(error, { queryKey }) {
    if (queryKey.at(0) === prospectKey().at(0)) return;

    onError(error);
  },
});

const mutationCache = new MutationCache({
  onError,
});

export default new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
    },
  },
});
