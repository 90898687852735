import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import LOCAL_STORAGE_KEYS from "constants/localStorageKeys";
import localStorageContext from "context/localStorageContext/context";
import initStorage from "context/localStorageContext/initStorage";
import localStorageHelper from "helpers/storage";

const ProviderComponent = localStorageContext.Provider;

const KEYS = Object.values(LOCAL_STORAGE_KEYS);
const DELAY = 300;

export default function LocalStorageProvider({ children }) {
  const [storage, setStorage] = useState(() => initStorage(KEYS));

  const setItem = useCallback(
    (key, value) => setStorage((prevState) => ({ ...prevState, [key]: value })),
    [],
  );

  const getItem = useCallback((key) => storage[key], [storage]);

  const removeItem = useCallback(
    (key) => setStorage((prevState) => ({ ...prevState, [key]: undefined })),
    [],
  );

  const clear = useCallback(() => setStorage({}), []);

  const key = useCallback((n) => Object.keys(storage)[n] ?? null, [storage]);

  /* update localStorage */
  useEffect(() => {
    if (!window.localStorage) return undefined;

    const timerId = setTimeout(() => {
      KEYS.forEach((storageKey) => {
        const value = storage[storageKey];

        if (value === undefined) {
          localStorageHelper.unset(storageKey);

          return;
        }

        localStorageHelper.set(storageKey, value);
      });

      clearTimeout(timerId);
    }, DELAY);

    return () => clearTimeout(timerId);
  }, [storage]);

  return (
    <ProviderComponent value={{ key, setItem, getItem, removeItem, clear }}>
      {children}
    </ProviderComponent>
  );
}

LocalStorageProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
