export default {
  get(key) {
    const stored = localStorage.getItem(key);

    if (stored === null) {
      return undefined;
    }

    try {
      return JSON.parse(stored);
    } catch (error) {
      console.error(error);

      return stored;
    }
  },
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  unset(key) {
    localStorage.removeItem(key);
  },
};
