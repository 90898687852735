import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import RootElement from "routing/RootElement";

const AuthElement = lazy(() => import("routing/AuthElement"));
const PublicElement = lazy(() => import("routing/PublicElement"));

const AdminPage = lazy(() => import("pages/admin"));
const LandingPage = lazy(() => import("pages/landing"));
const NotFound = lazy(() => import("pages/notFound"));
const PolicyPage = lazy(() => import("pages/agreements/Policy"));
const TermsPage = lazy(() => import("pages/agreements/Terms"));
const WelcomePage = lazy(() => import("pages/welcome"));
const ResetPasswordPage = lazy(() => import("pages/resetPassword"));
const ReminderPage = lazy(() => import("pages/reminder"));
const Report = lazy(() => import("pages/report"));

const LoginComponent = lazy(() => import("components/Login"));
const TeamLoginComponent = lazy(() => import("components/TeamLogin"));

export default createBrowserRouter([
  {
    element: <RootElement />,
    children: [
      { path: "/", element: <LandingPage /> },
      {
        path: "/auth",
        element: <AuthElement />,
        children: [
          {
            path: "login",
            children: [
              { index: true, element: <LoginComponent /> },
              { path: "team", element: <TeamLoginComponent /> },
            ],
          },
          { path: "reset-password", element: <ResetPasswordPage /> },
          { path: "reminder/:id", element: <ReminderPage /> },
          { path: "welcome/:id", element: <WelcomePage /> },
        ],
      },
      { path: "/client/:client", element: <div>Client</div> },
      { path: "/admin/*", element: <AdminPage /> },
      {
        element: <PublicElement />,
        children: [
          { path: "terms", element: <TermsPage /> },
          { path: "policy", element: <PolicyPage /> },
          { path: "*", element: <NotFound /> },
        ],
      },
      {
        path: "/report",
        element: <PublicElement />,
        children: [
          { path: "partner/:id", element: <Report /> },
          { path: "phase/:id", element: <Report /> },
          { path: "seller/:id", element: <Report /> },
        ],
      },
    ],
  },
]);
