const ID_TYPES = ["string", "number"];

export default function idKeyFactory(createKeys) {
  return (ID) => {
    const valueType = typeof ID;
    if (!ID_TYPES.includes(valueType)) {
      console.error(`$Wrong ID type of ${valueType}. Expected types: ${ID_TYPES.join(", ")}`);
      return createKeys();
    }

    return [...createKeys(), { id: ID.toString() }];
  };
}
